<template>
  <div>
    <CRow class="mb-2">
      <CCol col="auto">
        <CButton class="btn-upload" @click="onOpen">
          <font-awesome-icon icon="camera" size="2x" />
          Aggiungi foto</CButton
        >
      </CCol>

      <CCol col="auto" v-if="thumbnail">
        <figure class="ui-figure">
          <img
            class="ui-figure__img"
            :width="width"
            :height="height"
            :src="thumbnail"
            @click="onOpen"
          />
          <CButton class="ui-figure__delete" @click="onRemove">×</CButton>
        </figure>
      </CCol>
    </CRow>

    <CModal
      v-if="showCropModal"
      :title="title"
      :show.sync="showCropModal"
      :closeOnBackdrop="false"
      :centered="true"
    >
      <form
        v-if="previewUrl === null"
        class="dropzone"
        :class="{ 'dropzone--is-dragover': isDragover }"
        method="post"
        enctype="multipart/form-data"
        @dragover.stop.prevent="isDragover = true"
        @dragenter.stop.prevent="isDragover = true"
        @dragleave.stop.prevent="isDragover = false"
        @dragend.stop.prevent="isDragover = false"
        @drop.stop.prevent="onDrop"
      >
        <div class="dropzone__input">
          <input
            class="dropzone__file"
            type="file"
            ref="uplaodFile"
            id="uplaodFile"
            @change="onFileChange"
            accept="image/png, image/gif, image/jpeg"
          />
          <label v-if="!loading" @click="onLabelClick">
            <font-awesome-icon icon="camera" />
            <br />
            Seleziona una foto
            <br />
            <span class="dropzone__dragndrop"> o trascinala qui</span>
          </label>
        </div>
        <div v-if="loading" class="dropzone__uploading">
          Caricamento in corso…
        </div>
        <div v-if="errors" class="dropzone__error">{{ errors }}</div>
      </form>

      <cropper
        v-if="showPreview"
        ref="cropper"
        class="cropper"
        :src="previewUrl"
        :stencil-props="stencilProps"
        @change="onCropperChange"
        @ready="onCropperReady"
      ></cropper>
      <template #footer>
        <CButton color="primary" variant="ghost" @click="onReplace"
          >SOSTITUISCI IMMAGINE</CButton
        >

        <CButton color="primary" variant="outline" @click="onCancel"
          >Annulla</CButton
        >

        <vue-ladda
          :loading="saving"
          data-style="zoom-in"
          button-class="btn btn-primary"
          @click="onSave"
          >Salva</vue-ladda
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";

import { apiUrl } from "../../../http";
import { decodeCoordinates, prepareCoordinates } from "../../../helpers/common";
const loadImage = require("blueimp-load-image");

const ratioEnum = Object.freeze({
  "300:155": 300 / 155,
  "1:1": 1,
  free: null,
});

export default {
  name: "LogoUpload",
  components: {
    Cropper,
  },

  props: {
    title: { type: String, default: "" },
    ratio: { type: String, default: "free" },
    image: { type: [Object], default: null },
  },

  data() {
    const aspectRatio = ratioEnum[this.ratio] || null;
    return {
      loading: false,
      saving: false,
      errors: "",
      isDragover: false,
      stencilProps: { aspectRatio },
      file: null,
      previewUrl: null,
      showCropModal: false,
      coordinates: null,
      imageData: null,
    };
  },
  computed: {
    showPreview: function () {
      return this.previewUrl !== null;
    },
    original() {
      if (this.image) {
        return this.image.image.img;
      }
      return null;
    },
    thumbnail() {
      if (this.image) {
        return this.image.image_thumb;
      }
      return null;
    },
    cropping() {
      if (this.image) {
        return decodeCoordinates(this.image.image_cropping);
      }
      return null;
    },

    height() {
      return 111;
    },

    width() {
      return this.stencilProps.aspectRatio !== null
        ? this.height * this.stencilProps.aspectRatio
        : 148;
    },
  },

  methods: {
    onLabelClick() {
      this.$refs.uplaodFile.click();
    },
    onOpen() {
      if (this.original !== null) {
        this.previewUrl = this.original;
      }
      if (this.image !== null) {
        this.imageData = this.image;
      }
      this.saving = false;
      this.showCropModal = true;
    },

    onFileChange(e) {
      const self = this;
      this.file = e.target.files[0];
      self.upload(this.file);
    },

    onCropperChange({ coordinates }) {
      this.coordinates = coordinates;
    },

    onCropperReady() {
      if (this.cropping && this.$refs.cropper) {
        this.$refs.cropper.setCoordinates({ ...this.cropping });
      }
    },

    onReplace() {
      this.file = this.previewUrl = null;
    },

    onCancel() {
      if (this.file === null) {
        this.showCropModal = false;
        return;
      }
      this.file = this.previewUrl = null;
    },

    onDrop(e) {
      this.isDragover = false;
      this.file = e.dataTransfer.files[0];
      this.upload(this.file);
    },

    onSave() {
      this.saving = true;
      this.saveImageCropping()
        .then((payload) => {
          this.$emit("onSave", {
            imageData: payload,
            coordinates: this.coordinates,
          });
          this.saving = false;
          this.showCropModal = false;
        })
        .catch((response) => {
          console.error(response);
        });
    },

    onRemove() {
      this.$emit("onRemove");
    },

    saveImageCropping() {
      if (!this.imageData || !this.coordinates) {
        return Promise.reject(new Error("missing required params"));
      }
      const url = apiUrl(`/api/role/${this.$store.state.role.id}/imagecrop/`);
      const formData = {
        image: this.imageData.image.id,
        image_cropping: prepareCoordinates(this.coordinates),
        image_ratio: this.ratio,
      };
      return this.axios.post(url, formData).then((response) => {
        return response.data.payload;
      });
    },

    upload(file) {
      const self = this;
      const url = apiUrl(`/api/role/${this.$store.state.role.id}/image/`);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      const options = {
        maxWidth: 1920,
        maxHeight: 1920,
        meta: true,
        canvas: true,
      };

      loadImage(file, options)
        .then(function (data) {
          return new Promise(function (resolve) {
            data.image.toBlob(function (blob) {
              resolve(new File([blob], "filename.jpeg"));
            }, "image/jpeg");
          });
        })
        .then(function (data) {
          const formData = new FormData();
          formData.append("img", data);
          self.loading = true;
          self.errors = "";
          return self.axios
            .post(url, formData, config)
            .then((response) => {
              self.loading = false;
              const { payload } = response.data;
              self.previewUrl = payload.img;
              self.imageData = { image: payload };
              return response;
            })
            .catch((error) => {
              console.log(error.response.data);
              self.errors = "Errore sul caricamento dell'immagine";
            })
            .finally(() => {
              self.loading = false;
            });
        })
        .catch(function (err) {
          self.errors = err.toString();
        });
    },
  },
};
</script>

<style scoped>
.cropper {
  height: 600px;
}
</style>
